<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="mb-3 elevation-1">
          <v-card outlined>
            <v-card-title
              class="title pb-0"
              style="position: relative; z-index: 2"
            >
              <v-flex xs3 sm3>
                <v-select
                  :disabled="gradeLoading"
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs2 sm2>
                <v-select
                  :disabled="!months.length"
                  :loading="monthLoading"
                  :items="months"
                  class="pa-0"
                  label="Month"
                  v-model="month"
                  outlined
                  dense
                />
              </v-flex>

              <v-flex sm2>
                <search-button
                  :disabled="!grade || !month"
                  permission="bill-generate-class-wise"
                  style=""
                  @action="
                    (categoryToggle = false),
                      (selected = []),
                      (selectedStudents = []),
                      (generate = false),
                      (print = false),
                      fetchGradeFee(),
                      getBilling(),
                      get()
                  "
                >
                  Search
                </search-button>
              </v-flex>
<v-spacer></v-spacer>
              <v-flex
                sm3
                style="text-align: right; margin-top: -20px"
                v-if="
                  form.items.data.length && dataStatsCounts.generateCount > 0
                "
              >
              <!-- {
        billing:"1st",
        name:"Baishak",
        selected:false,
        value:"01"
      } -->
                <v-download-column-select
                  v-if="
                    form.items.data.length && dataStatsCounts.generateCount > 0
                  "
                  @selectedItems="selectedMonthItems"
                  :downloadColumns="downloadColumns"
                  :additionalOptions="additionalOptions"
                  @onDownload="printBill"
                  :showExcel="true"
                  :downloadPDFText="'Print Only Due'"
                  :downloadExcelText="'Print Now'"
                  >Print Options</v-download-column-select
                >
              </v-flex>
            </v-card-title>
          </v-card>
          <Loader v-if="showloader"></Loader>
          <div
            v-if="isCurrentBatch"
            :class="'category_selector ' + (gradeFeeHeads.length ? 'pa-3' : '')"
          >
            <div
              v-if="selected.length && categoryToggle === true"
              class="expand_contract"
            >
              <span @click="categoryToggle = false"
                ><v-icon>add</v-icon> Expand</span
              >
            </div>
            <div v-if="categoryToggle === false && gradeFeeHeads.length">
              <v-data-table
                :headers="categoryHeaders"
                v-model="selected"
                :items="gradeFeeHeads"
                :server-items-length="gradeFeeHeads.length"
                hide-default-footer
                :show-select="isCurrentBatch"
                item-key="id"
                :loading="form.loading"
              >
                <template v-slot:item.name="{ item }">
                  <tr>
                    <td>{{ item.fee_head ? item.fee_head.title : '-' }}</td>
                  </tr>
                </template>

                <template v-slot:item.amount="{ item }">
                  <td>{{ item.amount.numberFormat() }}</td>
                </template>

                <template v-slot:item.action="{ item }">
                  <v-btn
                    class="text-right" 
                    :disabled="fisibleInvoicesForHeadAddition.length < 1"
                    @click="(selectedAddHead = item), (addNewHead = true)"
                    primary
                    outlined
                    x-small
                    >Add</v-btn
                  >
                </template>
                <template v-slot:body.append>
                  <tr>
                    <th :colspan="isCurrentBatch ? '3' : '2'">Total</th>
                    <th>{{ selectedTotal.numberFormat('RS') }}</th>
                  </tr>
                </template>
              </v-data-table>

              <v-btn
                @click="categoryToggle = true"
                block
                :disabled="selected.length < 1 || !isCurrentBatch"
                large
                color="success"
                outlined
              >
                Select & Close (<strong> {{ selected.length }}&nbsp;</strong
                >/<strong>{{ gradeFeeHeads.length }}&nbsp; </strong>
                <small>heads</small>
                )
              </v-btn>
              <br />
              <v-btn
                @click="customizedFeeModal = true"
                block
                outlined
                color="primary"
              >
                Customized Fee (<strong
                  >{{ selectedCustomizedFee.length }} /
                  {{ customizedFees.length }} &nbsp; </strong
                ><small> Heads </small>)
              </v-btn>
            </div>

            <div
              class="selected-category-list"
              v-else-if="gradeFeeHeads.length"
            >
              <v-flex xs10 sm10>
                <v-chip
                  class="amount-chip mr-2"
                  label
                  v-for="(item, key) in selected"
                  :key="key"
                >
                  {{ item.fee_head.title }}
                  ({{ item.amount.numberFormat() }})
                </v-chip>
                <v-chip style="border-radius: 3px" class="primary"
                  ><span style="color: white"
                    ><strong
                      >TOTAL {{ selectedTotal.numberFormat('RS') }}</strong
                    ></span
                  >
                </v-chip>
                &nbsp;
                <v-chip style="border-radius: 3px" class="success">
                  <span style="color: white"
                    >(<strong>{{ selectedCustomizedFee.length }} </strong
                    ><small> Customized Fee </small>)</span
                  >
                </v-chip>
              </v-flex>
            </div>
          </div>
          <v-divider></v-divider>
        </v-card>

        <v-card v-if="form.items.data.length" class="pa-3 generate_records">
          <div class="adjustable_header">
            <div style="display: flex">
              <!--v-if="form.items.data.length"-->
              <div class="data-represent" style="margin-top: 8px">
                <div>
                  <span class="d-success"></span> &nbsp;
                  <small> Paid</small>
                </div>
                <div>
                  <span class="d-warning"></span> &nbsp;
                  <small> Partially Paid</small>
                </div>

                <div>
                  <span class="d-error"></span> &nbsp;
                  <small> Unpaid</small>
                </div>
                <div
                  style="cursor: pointer"
                  v-if="invoice_with_advance.length > 0"
                  @click="scrollToAdvance"
                >
                  <span class="d-success"></span> &nbsp;
                  <small
                    ><strong>{{ invoice_with_advance.length }}</strong> With
                    Advance</small
                  >
                </div>

                <div>
                  <span class="d-primary"></span> &nbsp;
                  <small
                    ><strong>{{ dataStatsCounts.notGenerated }}</strong> Bill
                    not generated</small
                  >
                </div>

                <div>
                  <span class="d-info"></span> &nbsp;
                  <small
                    ><strong>{{ form.items.meta.total }}</strong> Students
                  </small>
                </div>
              </div>

              <!--{{billingData}}-->
              <ul
                v-if="isCurrentBatch || $auth.isAdmin()"
                class="gen_roll_switch"
              >
                <li>
                  <a
                    href=""
                    @click.prevent="selectedTabMode = 'generate'"
                    :class="{
                      generate: true,
                      selected: selectedTabMode === 'generate',
                    }"
                  >
                    <v-icon>insert_drive_file</v-icon>
                    GENERATE ({{ dataStatsCounts.notGenerated }})</a
                  >
                </li>
                <li>
                  <a
                    href=""
                    @click.prevent="
                      dataStatsCounts.rollbackCount > 0 &&
                      (isCurrentBatch || $auth.isAdmin())
                        ? (selectedTabMode = 'rollback')
                        : ''
                    "
                    :class="{
                      rollback: true,
                      selected: selectedTabMode === 'rollback',
                      disabled:
                        dataStatsCounts.rollbackCount < 1 &&
                        (isCurrentBatch || !$auth.isAdmin()),
                    }"
                  >
                    <v-icon>refresh</v-icon>
                    ROLLBACK ({{ dataStatsCounts.rollbackCount }})</a
                  >
                </li>
              </ul>
            </div>
            <p
              style="padding-left: 20px; color: #777"
              v-if="selectedTabMode === 'generate'"
            >
              <strong style="color: #555; font-size: 16px">{{
                selectedStudents.length
              }}</strong>
              students selected, Total Amount generated on invoice is
              <strong style="color: #555; font-size: 16px">{{
                (selectedTotal * selectedStudents.length).numberFormat('RS')
              }}</strong>
            </p>
            <p v-else style="padding-left: 20px; color: #777">
              <strong style="color: #555; font-size: 16px">{{
                rollbackSelectedStudents.length
              }}</strong>
              students selected, Total Amount rolledback from invoice is
              <strong style="color: #555; font-size: 16px">{{
                selectedRollbackTotal.numberFormat('RS')
              }}</strong>
            </p>
          </div>

          <!--:show-select="isCurrentBatch"-->

          <v-data-table
            height="400"
            fixed-header
            v-model="selectedStudents"
            :headers="headers"
            :items="billingData"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="name"
            show-expand
            :server-items-length="form.items.meta.total"
            :search="search"
            :options.sync="pagination"
            :loading="form.loading"
            hide-default-footer
          >
            <template v-slot:header.select>
              <v-checkbox
                class="ml-0"
                v-if="form.items.data.length && selectedTabMode === 'generate'"
                primary
                :input-value="
                  selectedStudents.length === dataStatsCounts.notGenerated &&
                  selectedStudents.length !== 0
                "
                :indeterminate="
                  selectedStudents.length !== 0 &&
                  selectedStudents.length < dataStatsCounts.notGenerated
                "
                @click.stop="toggleAllStudent"
                hide-details
              ></v-checkbox>

              <v-checkbox
                v-else-if="
                  form.items.data.length && selectedTabMode === 'rollback'
                "
                color="#ff5252"
                :input-value="
                  rollbackSelectedStudents.length === billingData.length &&
                  rollbackSelectedStudents.length !== 0
                "
                :indeterminate="
                  rollbackSelectedStudents.length !== 0 &&
                  rollbackSelectedStudents.length < billingData.length
                "
                @click.stop="toggleAllRollbackStudent"
                hide-details
              ></v-checkbox>

              <span v-else>#</span>
            </template>

            <template v-slot:item="{ index, item, expand, isExpanded }">
              <tr
                :title="item.is_posted ? 'Posted to account' : ''"
                :class="{
                  is_posted_to_account: item.is_posted,
                  highlight: true,
                  advance_pending: item.has_advance_pending,
                }"
                :active="selectedStudents.includes(item)"
              >
                <td
                  :class="{
                    'd-alert-row': true,
                    'd-warningd':
                      item.bills.length > 0 &&
                      item.bills[0].invoice_status === '3',
                    'd-successd':
                      item.bills.length > 0 &&
                      (dueAmount(item.bills[0], index) === '0.00' ||
                        dueAmount(item.bills[0], index) === 0),
                    'd-errord':
                      item.bills.length > 0 &&
                      paidAmount(item.bills[0], index) === 0,
                    'd-primaryd': item.bills.length < 1,
                  }"
                  @click="
                    selectedTabMode === 'generate' ? selectStudent(item) : ''
                  "
                >
                  <v-checkbox
                    v-if="selectedTabMode === 'generate'"
                    :disabled="item.bills.length > 0"
                    :input-value="
                      selectedStudents.includes(item) || item.bills.length > 0
                    "
                    primary
                    hide-details
                  >
                  </v-checkbox>

                  <v-checkbox
                    color="#ff5252"
                    v-if="selectedTabMode === 'rollback'"
                    :disabled="item.bills.length < 1"
                    :input-value="rollbackSelectedStudents.includes(item)"
                    primary
                    @click.stop="selectStudent(item)"
                    hide-details
                  >
                  </v-checkbox>
                </td>
                <td class="text-xs-left">
                  {{ index + 1 }}
                </td>
                <td class="text-xs-left">
                  {{ item.enroll_code }}
                  <span v-if="item.bills.length">
                    <v-icon
                      title="Posted to account"
                      small
                      v-if="item.bills[0].invoice_no && item.is_posted"
                      color="teal"
                    >
                      check_circle
                    </v-icon>
                  </span>
                </td>
                <td
                  :class="
                    'text-xs-left ' +
                    (item.cancelled_invoice_no != null ? 'was_cancelled' : '')
                  "
                >
                  {{
                    item.bills.length > 0
                      ? item.bills[0].invoice_no
                        ? item.bills[0].invoice_no.split('-').pop()
                        : 'N/A'
                      : item.cancelled_invoice_no || 'N/A'
                  }}
                </td>
                <td class="text-xs-left">{{ item.roll }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">{{ item.section }}</td>
                <td class="text-xs-left">
                  {{
                    item.bills.length > 0 ? dueAmount(item.bills[0], index) : ''
                  }}
                </td>
                <td class="text-xs-right">
                  <v-btn
                    v-if="$auth.can('bill-generate-student-wise')"
                    small
                    icon
                    color="primary"
                    class="pa-0 ma-1"
                    @click="
                      singlePrint(
                        item.enroll_code,
                        item.enroll_id,
                        item.bill_date,
                        item.inv_sequence
                      )
                    "
                    :disabled="
                      item.bills.length > 0
                        ? item.bills[0].invoice_no
                          ? false
                          : true
                        : true
                    "
                  >
                    <v-icon dark>print</v-icon>
                  </v-btn>
                  <v-btn
                    title="Only Due"
                    v-if="
                      $auth.can('bill-generate-student-wise') &&
                      item.bills.length > 0 &&
                      item.bills[0].invoice_status === '3'
                    "
                    small
                    icon
                    color="error"
                    class="pa-0 ma-1"
                    @click="
                      singlePrint(
                        item.enroll_code,
                        item.enroll_id,
                        item.bill_date,
                        item.inv_sequence,
                        true
                      )
                    "
                    :disabled="
                      item.bills.length > 0
                        ? item.bills[0].invoice_no
                          ? false
                          : true
                        : true
                    "
                  >
                    <v-icon dark>print</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    class="mt-1"
                    small
                    outlined
                    @click="expand(!isExpanded)"
                    :disabled="
                      item.bills.length > 0
                        ? item.bills[0].invoice_no
                          ? false
                          : true
                        : true
                    "
                    >{{
                      isExpanded
                        ? 'Close'
                        : `${
                            item.bills.length > 0
                              ? '(' + item.bills[0].details.length + ')'
                              : ''
                          } Details`
                    }}
                  </v-btn>
                  <v-btn
                    v-if="
                      item.bills.length > 0 &&
                      item.bills[0].invoice_no &&
                      dueAmount(item.bills[0], index) > 0
                    "
                    @click="payNow(item)"
                    color="primary"
                    outlined
                    class="mt-2 mb-2"
                    small
                    >Pay now</v-btn
                  >
                </td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td v-if="item.bills.length > 0" :colspan="headers.length">
                <v-card
                  class="elevation-1"
                  v-if="item.bills.length > 0"
                  style="width: 100%"
                >
                  <table class="table" style="width: 100%">
                    <thead>
                      <tr>
                        <th class="text-xs-left"><strong>Fee Head</strong></th>
                        <th class="text-xs-left">
                          <strong>Fee Head Amount </strong>
                        </th>
                        <th class="text-xs-left"><strong>Discount </strong></th>
                        <th class="text-xs-left">
                          <strong>Scholarship </strong>
                        </th>
                        <th class="text-xs-left">
                          <strong>Paid Amount</strong>
                        </th>
                        <th class="text-xs-left">
                          <strong>Due Amount</strong>
                        </th>
                        <th class="text-right">
                          <strong>Action</strong>
                        </th>
                      </tr>
                      <tr
                        v-for="(x, i) in item.bills[0].details"
                        :key="i"
                        style="height: 0px"
                      >
                        <td class="text-xs-left">{{ x.fee_head }}</td>
                        <td class="text-xs-left">
                          {{ x.fee_head_amount.currency() }}
                        </td>
                        <td class="text-xs-left">{{ x.discount }}%</td>
                        <td class="text-xs-left">{{ x.scholarship }}%</td>
                        <td class="text-xs-left">
                          <span style="color: #4caf50 !important">{{
                            x.paid_amount.currency()
                          }}</span>
                        </td>
                        <td class="text-xs-left">
                          <strong style="color: #ff5252">{{
                            x.due_amount.currency()
                          }}</strong>
                        </td>
                        <td class="text-right">
                          <v-btn
                            :disabled="
                              (item.invoice_payment_status !== 0 ||
                                item.is_posted === true) &&
                              !$auth.isAdmin()
                            "
                            @click="
                              (selectedExtraItem = { ...item, details: x }),
                                (extraDeleteAlert = true)
                            "
                            outlined
                            x-small
                            color="error"
                            >Delete</v-btn
                          >
                        </td>
                      </tr>
                    </thead>
                  </table>
                </v-card>
              </td>
            </template>
          </v-data-table>
          <v-flex xs12 v-if="display_transport_alert !== 'false'">
            <alert-message
              type="error"
              title="Transport Disabled !!!"
              :extraSmall=true
            >
              <template v-slot:message>
                Transportation fee will not be generated as you have disabled the settings for this. To enable the transport fee generation please go to 
                <strong>Settings > Billing > <router-link to="/settings">Exclude Transport Charge</router-link> </strong>
                and uncheck the option.
              </template>
            </alert-message>
          </v-flex>
          <br />
          <!-- || !sod_date -->
          <v-btn
            v-if="
              selectedTabMode === 'generate' &&
              (isCurrentBatch || $auth.isAdmin())
            "
            @click="getAdvanceModal"
            block
            :disabled="canGenerateInvoice"
            large
            color="primary"
          >
            Generate Invoice (<strong
              >{{ selectedStudents.length }}&nbsp;</strong
            >/<strong>{{ billingData.length }} &nbsp;</strong>
            <small>students</small>
            )
          </v-btn>
          <!-- !sod_date -->
          <v-btn
            @click="reverseBill"
            v-if="selectedTabMode === 'rollback'"
            :disabled="
              rollbackSelectedStudents.length < 1 &&
              (!isCurrentBatch || !$auth.isAdmin())
            "
            block
            large
            color="#ff5252"
            ><span style="color: white"
              ><v-icon style="color: white">refresh</v-icon> Rollback (<strong
                >{{ rollbackSelectedStudents.length }}&nbsp;</strong
              >/<strong>{{ billingData.length }} &nbsp;</strong>
              <small>students</small>
              )</span
            ></v-btn
          >
        </v-card>

        <div class="nf_placeholder mt-5" v-if="!billingData.length">
          <not-found
            :width="350"
            text="Please selected the required grade, month and select the search button to fetch the invoice list."
          ></not-found>
        </div>
      </v-flex>
      <v-dialog v-model="confirmDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0">{{ warningMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="confirmDialog = false"
              >No</v-btn
            >
            <v-btn
              v-if="selectedTabMode === 'generate'"
              outlined
              color="warning"
              text
              small
              @click="generateBill"
            >
              Yes
            </v-btn>
            <v-btn
              v-if="selectedTabMode === 'rollback'"
              outlined
              color="warning"
              text
              small
              @click="reverseBill"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="addNewHead" persistent max-width="700">
        <v-card>
          <v-card-title class="title pa-3 primary white--text">
            <v-icon class="mr-2 white--text">info</v-icon>
            Add New Head
          </v-card-title>
          <v-card-text class="ma-0">
            <br />
            <alert-message
              extra-small
              type="warning"
              :title="
                selectedAddHead &&
                selectedAddHead.fee_head &&
                `${
                  selectedAddHead.fee_head.title
                } (${selectedAddHead.amount.currency()})`
              "
              message="Selected fee head cannot be added to invoices that have already been posted to account or have received any payment"
            ></alert-message>
            <br />
            <br />
            <br />
            <v-select
              style="margin-top: -20px"
              :items="fisibleInvoicesForHeadAddition"
              v-model="selectedInvoiceForHeadAddition"
              :label="`Select Student (Total ${selectedInvoiceForHeadAddition.length} selected)`"
              multiple
              class="pt-0"
              outlined
              dense
            >
              <v-list-item
                slot="prepend-item"
                ripple
                @click="toggleFeeHeadAddInvoiceSelect"
              >
                <v-list-item-action>
                  <v-icon
                    :color="
                      fisibleInvoicesForHeadAddition > 0
                        ? 'indigo darken-4'
                        : ''
                    "
                  >
                    {{
                      fisibleInvoicesForHeadAddition.length !==
                        selectedInvoiceForHeadAddition.length &&
                      selectedInvoiceForHeadAddition.length > 0
                        ? 'indeterminate_check_box'
                        : fisibleInvoicesForHeadAddition.length ===
                          selectedInvoiceForHeadAddition.length
                        ? 'check_box'
                        : 'add_box'
                    }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item>
              <v-divider slot="prepend-item" class="mt-2"></v-divider>
              <v-divider slot="append-item" class="mb-2"></v-divider>
            </v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              outlined
              small
              @click="
                (selectedAddHead = null),
                  (selectedInvoiceForHeadAddition = []),
                  (addNewHead = false)
              "
              >Cancel</v-btn
            >
            <v-btn
              :disabled="selectedInvoiceForHeadAddition.length < 1"
              :loading="newHeadAdding"
              outlined
              color="primary"
              @click="addNewHeadToInvoice"
              text
              small
            >
              Add Now
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmSyncDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0">
            Are you sure ,This bills has been completed?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text small @click="confirmSyncDialog = false"
              >No</v-btn
            >
            <v-btn color="warning" text small @click="syncToAccount">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="reasonDialog" persistent max-width="500">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Rollback Reason!
          </v-card-title>
          <br />
          <v-card-title class="title pa-3">
            <v-textarea
              outlined
              name="input-7-4"
              label="Reason to Rollback"
              v-model="reason"
            ></v-textarea>

            <br />
            <alert-message
              :extraSmall="true"
              type="warning"
              :title="'Rollback!! (' + $auth.user().full_name + ')'"
              message="Make sure what you are doing. Frequent rollback actions might cause issue on the billing process"
            />
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text small @click="reasonDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              :disabled="reason.length < 3"
              color="warning"
              text
              small
              @click="rollBack"
              >Rollback
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3">
            <v-select
              :disabled="!fees.length"
              :items="fees"
              class="pa-0"
              label="Fee Head"
              v-model="fee_head_id"
            />
            <v-text-field
              :disabled="
                feeOfferDiscountPer > 0 ||
                feeOfferScholarPer > 0 ||
                !fee_head_id
              "
              autocomplete="off"
              :label="labelFeeHead"
              required
              class="pa-0 pt-3"
              v-model="total"
              :persistent-hint="true"
              :hint="
                feeOfferDiscountPer > 0
                  ? 'Fee Offer Discount ' + feeOfferDiscountPer + '%'
                  : '' + feeOfferScholarPer > 0
                  ? 'Fee Offer Scholar ' + feeOfferScholarPer + '%'
                  : ''
              "
            />
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text small @click="addDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              :disabled="!fee_head_id"
              color="warning"
              text
              small
              @click="submit"
              >Add</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="alertDialog" persistent max-width="460px">
        <v-card>
          <v-card-title class="title pa-3 error white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0">
            Previous Due Journal Entry process is still pending.
            <br />
            If you continue billing, Students' Previous Due will not included in
            this billing.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text small @click="alertDialog = false"
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="advanceAlert" persistent max-width="600px">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Advance Alert!
          </v-card-title>
          <v-card-text class="ma-0">
            <br />
            <alert-message
              type="warning"
              title="Advance Alert"
              :message="
                invoice_with_advance.length +
                ' student\'s found with advance amount. Please pay them from the fee payment page.'
              "
            ></alert-message>
            <br />
            <strong>NOTE: </strong> Click the advance student count on the stats
            info to point out student with advance.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text small @click="advanceAlert = false"
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="extraDeleteAlert" persistent max-width="600px">
        <v-card>
          <v-card-title class="title pa-3 error white--text">
            <v-icon class="mr-2 white--text">error</v-icon>
            Please wait!
          </v-card-title>
          <v-card-text class="ma-0">
            <br />
            <alert-message
              extraSmall
              type="error"
              title="Are you sure?"
              :message="`Deleted item cannot be recovered again. Only perform action if you are 100% sure what you are doing.`"
            ></alert-message>
            <br />
            <hr />
            <table
              class="basic_styled_table"
              width="100%"
              v-if="selectedExtraItem"
              border="1"
              cellpadding="5"
              cellspacing="5"
            >
              <tbody>
                <tr>
                  <td>Student Name</td>
                  <td>
                    {{ selectedExtraItem.name }} ({{
                      selectedExtraItem.enroll_code
                    }})
                  </td>
                </tr>
                <tr>
                  <td>Fee Head / Invoice No</td>
                  <td>
                    {{ selectedExtraItem.details.fee_head }} /
                    {{ selectedExtraItem.details.invoice_no }}
                  </td>
                </tr>
                <tr>
                  <td>Amount / Due</td>
                  <td>
                    {{ selectedExtraItem.details.total.currency() }} /
                    {{ selectedExtraItem.details.due_amount.currency() }}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <strong>NOTE: </strong> Deleting a head is not allowed in the
            selected invoice is fully or partially paid.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              text
              small
              @click="(extraDeleteAlert = false), (selectedExtraItem = null)"
              >Cancel</v-btn
            >
            <v-btn
              :loading="extraDeleting"
              color="error"
              text
              small
              @click="extraDelete()"
              >Delete Now</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="customizedFeeModal" persistent max-width="800px">
        <v-card>
          <v-card-title class="title pa-3 info white--text">
            <v-icon class="mr-2 white--text">info</v-icon>
            Customized Fee Head
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <alert-message
              extraSmall
              type="warning"
              title="Warning"
              message="The Selected Customized Fee Head will Override All The Bill Generate Amount For Selected Fee head "
            ></alert-message>
          </v-card-text>
          <br />
          <v-flex xs12>
            <v-data-table
              :headers="CutomizedFeeheaders"
              :items="customizedFees"
              hide-default-footer
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td @click="selectCustomizedFeeHead(item)">
                    <v-checkbox
                      :input-value="selectedCustomizedFee.includes(item)"
                    ></v-checkbox>
                  </td>
                  <td class="text-xs-left">{{ index + 1 }}</td>
                  <td class="text-xs-left">{{ item.title }}</td>
                  <td class="text-xs-left">{{ '-' }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="success"
              outlined
              small
              @click="customizedFeeModal = false"
              >Update Selection</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showAdvanceModal" persistent max-width="720px">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Select Advance Payment Type!!
          </v-card-title>
          <v-card-text>
            <br />
            <div class="table_advance" v-if="advances.length > 0">
              <table class="customers">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Enroll Code</th>
                    <th>Name</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in advances" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.enroll_code }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.amount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <div class="acc_selector">
              <div
                @click="(payWithAdvance = true), (payWithoutAdvance = false)"
                :class="{
                  personal: true,
                  select_box: true,
                  active: payWithAdvance === true,
                }"
              >
                <v-icon
                  class="mr-2 white--text"
                  style="color: #00b365 !important; font-size: 25px !important"
                  >autorenew</v-icon
                >
                <p style="margin-bottom: 0; font-weight: 500; margin-top: 10px">
                  Advance auto deduct
                </p>
                <small
                  >Advance amount will be auto detected from the generate
                  student's bill.</small
                >
              </div>
              <div
                @click="(payWithAdvance = false), (payWithoutAdvance = true)"
                :class="{
                  company: true,
                  select_box: true,
                  active: payWithoutAdvance === true,
                }"
              >
                <v-icon
                  style="color: #00b365 !important; font-size: 25px !important"
                  class="mr-2 white--text"
                  >pan_tool_alt</v-icon
                >

                <p style="margin-bottom: 0; font-weight: 500; margin-top: 10px">
                  Manual advance deduct
                </p>
                <small
                  >Advance deduction will be done throught manual
                  process.</small
                >
              </div>
            </div>

            <br />

            <alert-message
              v-if="payWithAdvance === true"
              type="warning"
              title="Auto Advance Deduction"
              :message="`Please Note that any advance amount will get auto detucted from the generated bills. Rollbacks are not allowed once the generated bills are fully or parially paid.`"
            />
            <alert-message
              v-if="payWithoutAdvance === true"
              type="info"
              title="Manual Advance Deduct"
              :message="`Please Note that auto amount payment will be ignored and payment will have to carried out manually by the accountant from Fee Payment or with Bulk Advance Payment Option.`"
            ></alert-message>
          </v-card-text>

          <hr />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              small
              outlined
              densed
              @click="
                (showAdvanceModal = false),
                  (payWithAdvance = false),
                  (payWithoutAdvance = false)
              "
              >Cancel</v-btn
            >
            <v-btn
              :disabled="!payWithAdvance && !payWithoutAdvance"
              color="success"
              text
              small
              @click="generateBill()"
              >Confirm ({{ payWithAdvance === true ? 'Auto' : 'Manual' }}) and
              Generate
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import Form from '@/library/Form';
import Mixins from '@/library/Mixins';
import { getPropertyFromArrayObject } from '@/library/helpers';
const NepaliDate = require('nepali-date');
import Loader from '../../../../components/Loader.vue';

const dt = new NepaliDate();

export default {
  components: { Loader },
  mixins: [Mixins],
  data: () => ({
    extraDeleteAlert: false,
    selectedExtraItem: null,
    extraDeleting: false,
    newHeadAdding: false,
    selectedAddHead: null,
    addNewHead: false,
    fisibleInvoicesForHeadAddition: [],
    selectedInvoiceForHeadAddition: [],
    // downloadColumns: [],
    selectedMonths: [],
    singleSelect: false,
    selectedTabMode: 'generate',
    categoryToggle: false,
    advanceAlert: false,
    sod_date_back: dt.format('YYYY-MM-DD'),
    downloadColumns:[
      {
        billing:"1st",
        name:"Baishak",
        selected:false,
        value:"01"
      },
      {
        billing:"2nd",
        name:"Jestha",
        selected:false,
        value:"02"

      },
      {
        billing:"3rd",
        name:"Ashad",
        selected:false,
        value:"03"
      },
      {
        billing:"4th",
        name:"Shrawan",
        selected:false,
        value:"04"
      },
      {
        billing:"5th",
        name:"Bhadra",
        selected:false,
        value:"05"
      },
      {
        billing:"6th",
        name:"Ashoj",
        selected:false,
        value:"06"
      },
      {
        billing:"7th",
        name:"Kartik",
        selected:false,
        value:"07"
      },
      {
        billing:"8th",
        name:"Mangsir",
        selected:false,
        value:"08"
      },
      {
        billing:"9th",
        name:"Poush",
        selected:false,
        value:"09"
      },
      {
        billing:"10th",
        name:"Magh",
        selected:false,
        value:"10"
      },
      {
        billing:"11th",
        name:"Falgun",
        selected:false,
        value:"11"
      },
      {
        billing:"12th",
        name:"Chaitra",
        selected:false,
        value:"12"
      },
      // {
      //   billing:"1sh",
      //   name:"Baisakh (13)",
      //   selected:false,
      //   value:"13"
      // }
    ],
    additionalOptions:[
      {
        name:"Include General Receipt",
        selected: false,
        value:"includeNoMonth=true"
      },
      {
        name:"Only Current Batch",
        selected: false,
        value:"onlyCurrentBatch=true"
      }
    ],
    form: new Form(
      {
        bill_month: '',
        grade_id: '',
        billing: '',
        selected_students: [],
        selected_fee_heads: [],
        sum_amount: 0,
        cancelled_invoice: [],
        sod_date_back: '',
        generated_by: '',
        selected_customized_fee_heads: [],
        advance: [],
      },
      '/api/invoice'
    ),
    search: null,
    pagination: {
      itemsPerPage: 100,
      sortBy: ['roll'],
    },
    selected: [],
    selectedStudents: [],
    rollbackSelectedStudents: [],
    cancelled_invoice: [],
    rollbackCount: 0,
    generateCount: 0,
    categoryHeaders: [
      {
        text: 'S.N',
        align: 'left',
        value: 'sn',
        sortable: false,
      },
      {
        text: 'Fee Heads (Category)',
        align: 'left',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Amount',
        value: 'amount',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Action',
        value: 'action',
        align: 'right',
        sortable: false,
      },
    ],

    warningMessage: '',
    headers: [
      { text: '#', align: 'left', value: 'select', width: 3, sortable: false },
      { text: '#', align: 'left', value: 'id', sortable: false },
      {
        text: 'Enroll Code',
        align: 'left',
        value: 'enroll_code',
        sortable: false,
      },
      {
        text: 'Inv. No',
        align: 'left',
        value: 'invoice_no',
        sortable: false,
      },
      { text: 'Roll No', align: 'left', value: 'roll', sortable: false },
      { text: 'Name', align: 'left', value: 'name', sortable: false },
      { text: 'Section', align: 'left', value: 'section', sortable: true },
      {
        text: 'Due Amount',
        align: 'left',
        value: 'fee_head_amount',
        sortable: false,
      },
      { text: 'Action', align: 'right', value: 'action', sortable: false },
      { text: '', value: 'data-table-expand' },
    ],
    grades: [],
    grade: '', //20
    sections: [],
    section: '',
    months: [],
    month: '', //01
    generate: false,
    expand: false,
    expanded: [],
    singleExpand: true,
    print: false,
    billingSetting: {},
    confirmDialog: false,
    reasonDialog: false,
    addDialog: false,
    reason: '',
    filterData: [],
    fee_head_amount: '',
    labelFeeHead: 'Fee Head Amount',

    fee_heads: [],
    fee_head: '',
    fees: [],
    fee_head_id: '',
    feeAmounts: [],
    gradeLoading: false,
    monthLoading: false,
    itemData: [],
    feeOfferDiscountPer: 0,
    feeOfferScholarPer: 0,
    total: '',
    counter: 0,
    is_bill_generate: 0,
    is_journal_entry: 0,
    id: '',
    confirmSyncDialog: false,
    alertDialog: false,
    gradeFeeHeads: [],
    dataStatsCounts: {
      notGenerated: 0,
      paid: 0,
      partialPaid: 0,
      unPaid: 0,
      rollbackCount: 0,
      generateCount: 0,
    },
    sod_date: null,
    invoice_with_advance: [],
    batch: {},
    showloader: false,
    customizedFees: [],
    customizedFeeModal: false,
    selectedCustomizedFee: [],
    CutomizedFeeheaders: [
      { text: '#', align: 'left', value: 'select', width: 3, sortable: false },
      {
        text: 'S.No.',
        align: 'left',
        value: 'sn',
        sortable: false,
      },
      {
        text: 'Fee Heads',
        align: 'left',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Amount',
        value: 'amount',
        align: 'left',
        sortable: false,
      },
    ],
    showAdvanceModal: false,
    payWithAdvance: true,
    payWithoutAdvance: false,
    advances: [],
    display_transport_alert:'false',
  }),
  computed: {
    selectedTotal() {
      let output = 0;
      this.selected.map(function (item) {
        output += item.amount;
      });
      return output;
    },
    isCurrentBatch() {
      return this.batch && this.batch.is_current_batch === true;
    },
    selectedRollbackTotal() {
      let output = 0;
      this.rollbackSelectedStudents.map(function (item) {
        if (item.bills.length > 0) {
          output += item.bills[0].amount;
        }
      });
      return output;
    },
    selectedGrade() {
      let output = {};
      let $this = this;
      this.grades.map(function (item) {
        if (item.value === parseInt($this.grade)) {
          output = item;
        }
      });
      return output;
    },
    billingData() {
      const isGenerate = this.selectedTabMode === 'generate';
      if (isGenerate) return this.form.items.data;
      else {
        return this.form.items.data.filter(function (item) {
          return item.bills.length > 0 && item.flag_type === 'rollback';
        });
      }
    },

    canGenerateInvoice() {
      return (
        this.selectedStudents.filter((item) => item.invoice_no == null).length <
        1
      );
    },
  },

  mounted() {
    this.getGrades();
    this.batch = JSON.parse(window.localStorage.getItem('batch'));
  },

  watch: {
    batch: function (value) {
      // this.get();
    },
    month: function (value) {
      this.form.items.data = [];
      this.gradeFeeHeads = [];
    },
    pagination: function () {
      this.get();
    },
    grade: function (value) {
      this.form.items.data = [];
      this.month = '';
      if (this.grade) {
        this.getMonth();
      }
    },
    fee_head_id: function (value) {
      if (value) {
        this.$rest
          .get(
            '/api/student-fee-offer?' +
              'enrollId=' +
              this.itemData.enroll_id +
              '&feeHeadId=' +
              value
          )
          .then((result) => {
            if (result.data) {
              this.feeOfferDiscountPer = result.data.discount_percentage;
              this.feeOfferScholarPer = result.data.scholar_percentage;
            } else {
              this.feeOfferDiscountPer = 0;
              this.feeOfferScholarPer = 0;
            }

            if (this.feeOfferScholarPer > 0)
              this.total -= (this.feeOfferScholarPer / 100) * this.total;
            if (this.feeOfferDiscountPer > 0)
              this.total -= (this.feeOfferDiscountPer / 100) * this.total;

            if (
              (this.feeOfferDiscountPer > 0 || this.feeOfferScholarPer > 0) &&
              this.fee_head_amount > 0
            ) {
              this.labelFeeHead =
                this.labelFeeHead + ' Rs.' + this.fee_head_amount;
            } else {
              this.labelFeeHead = 'Fee Head Amount';
            }
          });

        this.feeAmounts = this.fees.find((res) => res.value === value);
        this.fee_head_amount = this.feeAmounts.amount;
        this.total = this.feeAmounts ? this.feeAmounts.amount : '';
        this.fee_head = this.feeAmounts ? this.feeAmounts.text : '';
      }
    },
  },

	methods: {
		addNewHeadToInvoice() {
			this.newHeadAdding = true;
			this.$rest
				.post('/api/invoice/add-item', {
					...this.selectedAddHead,
					invoice_list: this.selectedInvoiceForHeadAddition,
					fee_id: this.selectedAddHead.id,
				})
				.then(({ data }) => {
					this.addNewHead = false;
					this.$events.fire('notification', {
						message: data.message,
						status: 'success',
					});
					this.selectedInvoiceForHeadAddition = [];
					this.get();
				})
				.catch((err) => {
					this.$events.fire('notification', {
						message: err.response.data.message,
						status: 'error',
					});
				})
				.finally(() => {
					this.newHeadAdding = false;
				});
		},
		toggleFeeHeadAddInvoiceSelect() {
			this.$nextTick(() => {
				if (
					this.selectedInvoiceForHeadAddition.length ===
          this.fisibleInvoicesForHeadAddition.length
        ) {
          this.selectedInvoiceForHeadAddition = [];
        } else {
          this.selectedInvoiceForHeadAddition =
            this.fisibleInvoicesForHeadAddition;
        }
      });
    },
    selectedMonthItems(selectedMonths) {
      this.selectedMonths = selectedMonths;
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.gradeFeeHeads.slice();
    },
    get(params) {
      if (this.grade && this.selectedGrade.section_count) {
        this.showloader = true;
        this.pagination.itemsPerPage = this.selectedGrade.section_count * 200;
        if (!this.batch || !this.batch.start) {
          this.$events.fire('notification', {
            message:
              'Something not right please contact support <Switch Batch>',
            status: 'error',
          });
        }
        let filterDate = this.batch.start.substr(0, 4) + '-' + this.month;
        if (
          this.batch.is_hs === true &&
          ['01', '02', '03'].includes(this.month)
        ) {
          filterDate = this.batch.end.substr(0, 4) + '-' + this.month;
        }
        this.selectedMonths = [];
        this.downloadColumns = this.downloadColumns.map((item) => {
          if (item.value === this.month) {
            this.selectedMonths.push(item);
            item.selected = true;
          } else {
            item.selected = false;
          }
          return item;
        });

        let extraParams =
          '&billMonth=' +
          this.month +
          '&gradeId=' +
          this.grade +
          `&filterDate=${filterDate}`;
        let query = [null, undefined].includes(params)
          ? this.queryString(extraParams)
          : params;
        this.selectedStudents = [];

        this.selectedTabMode = 'generate';
        this.form
          .get(null, query)
          .then(({ data }) => {
            this.fetchCustomizedFee();

            let $this = this;
            this.sod_date = data.sod;
            $this.selectedStudents = [];
            $this.cancelled_invoice = data.cancelled_invoice;
            this.generate = false;
            $this.dataStatsCounts.notGenerated = 0;
            $this.dataStatsCounts.generateCount = 0;
            $this.dataStatsCounts.rollbackCount = 0;
            this.rollbackSelectedStudents = [];
            this.invoice_with_advance = [];
            this.fisibleInvoicesForHeadAddition = [];

            data.data.map(function (item) {
              if (
                (item.is_posted === false &&
                  item.invoice_payment_status === 0) ||
                ($this.$auth.isAdmin() &&
                  item.is_posted === false &&
                  [1, 3, 0].includes(item.invoice_payment_status))
              ) {
                $this.fisibleInvoicesForHeadAddition.push({
                  value: item.invoice_no,
                  text: item.name,
                });
              }
              if (
                item.advance_amount > 0 &&
                item.bills.length &&
                item.bills[0].invoice_status === '0'
              ) {
                $this.invoice_with_advance.push(item);
                item.has_advance_pending = true;
              }

              data.cancelled_invoice.map((inv) => {
                if (item.enroll_id === inv.enroll_id && item.bills.length < 1) {
                  item.cancelled_invoice_no = inv.cancelled_invoice_no
                    .split('-')
                    .pop();
                }
              });

              if (!item.invoice_no) {
                $this.selectedStudents.push(item);
                $this.generate = true;
                $this.dataStatsCounts.notGenerated += 1;
                item.flat_type = 'generate';
              } else {
                let amount = 0;
                // let isPartial = false;
                if (item.bills.length) {
                  item.bills.map((bill) => {
                    // if (bill.invoice_status === "3") {
                    //   isPartial = true;
                    // }
                    bill.details.map((detail) => {
                      amount += detail.due_amount;
                    });
                  });
                }
                if (
                  item.invoice_payment_status === 0 &&
                  item.is_posted === false
                ) {
                  $this.rollbackSelectedStudents.push(item);
                  $this.dataStatsCounts.rollbackCount += 1;
                  item.flag_type = 'rollback';
                }
                $this.dataStatsCounts.generateCount += 1;
              }
            });

            if ($this.invoice_with_advance.length > 0) {
              $this.advanceAlert = true;
            }

            if (data.stat) {
              this.is_bill_generate = data.stat.is_bill_generate;
              this.is_journal_entry = data.stat.is_journal_entry;
              this.id = data.stat.id;
            } else {
              this.is_bill_generate = 0;
              this.is_journal_entry = 0;
              this.id = '';
            }

            let j = 0;
            for (let i = 0; i < data.data.length; i++) {
              if (data.data[i].bills.length === 0) {
                j++;
              }
            }

            if (j === data.data.length) {
              this.generate = true;
              this.print = false;
            } else {
              this.print = true;
              this.generate = false;
            }
            this.getBillingSetting();
          })
          .catch((e) => {
            if (e.response)
              this.$events.fire('notification', {
                message: e.response.data.message,
                status: 'error',
              });
          })
          .finally(() => {
            this.showloader = false;
          });
      }
    },

    save() {
      this.form.batchId = this.batch.id;
      this.form.grade_id = this.grade;
      this.form.bill_month = this.month;
      this.form.store();
    },

    fetchGradeFee() {
      this.$rest
        .get(
          `/api/fee?rowsPerPage=50&gradeId=${this.grade}&feeMonth=${this.month}&rowsPerPage=25`
        )
        .then(({ data }) => {
          this.gradeFeeHeads = data.data;
          if(this.$auth.isAdmin() && this.display_transport_alert === 'false') {
            this.gradeFeeHeads.push({
                id: null,
                amount: 0,
                fee_head: {
                  id: null,
                  title: 'Transport'
                }
            })
          }
          let $this = this;
          this.selected = [];

          this.gradeFeeHeads.map(function (item, index) {
            item.sn = index + 1;
            if (item.generate_bill) $this.selected.push(item);
          });
        });
    },

    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          '/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true'
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return {
              value: item.id,
              text: item.name,
              section_count: item.sections.length,
            };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },

		getFeeHead() {
			this.$rest.get('/api/fee-head').then(({ data }) => {
				this.fee_heads = data.data.map((item) => {
					return { value: item.id, text: item.title };
				});
			});
		},
		getAdvanceModal() {
			this.getStudentAdvance().then(()=>{
				this.payWithAdvance = true;
				this.showAdvanceModal = true;
			})
		},
		getStudentAdvance() {
			return this.$rest
				.post(`/api/student-advance`, { students: this.selectedStudents })
				.then(({ data }) => {
					this.advances = data;
				});
		},
		generateBill() {
			if (!this.confirmDialog) {
				this.warningMessage = 'Are you sure you want to generate bill?';
				this.confirmDialog = true;
			} else {
				if (this.payWithAdvance == true) {
					this.form.advance = this.advances;
				}
				this.form.batch_id = this.batch.id;
				this.form.grade_id = this.grade;
				this.form.bill_month = this.month;
				this.form.billing = this.billing;
				this.form.sum_amount = this.selectedTotal;
				this.form.selected_students = this.selectedStudents.filter(
					(item) => item.invoice_no == null
				);
				this.form.selected_fee_heads = this.selected;
				this.form.sod_date_back = this.sod_date_back;
				this.form.cancelled_invoice = this.cancelled_invoice;
				this.form.selected_customized_fee_heads = this.selectedCustomizedFee;
				this.form.fireFetch = false;
				this.form.endpoint = '/api/invoiceNew';

        this.form
          .store()
          .then(({ data }) => {
            this.confirmDialog = false;
            this.showAdvanceModal = false;
            this.payWithAdvance = false;
            this.payWithoutAdvance = false;
            this.generate = false;
            this.print = true;
            this.form.endpoint = '/api/invoice';

            this.get();
          })
          .catch((e) => {
            this.confirmDialog = false;
            this.showAdvanceModal = false;
            this.payWithAdvance = false;
            this.payWithoutAdvance = false;
            this.$events.fire('notification', {
              message: data.message,
              status: 'error',
            });
            this.form.endpoint = '/api/invoice';
          });
      }
    },

    reverseBill() {
      this.reason = '';
      if (!this.confirmDialog) {
        this.warningMessage =
          'Are you sure you want to Rollback generated bill?';
        this.confirmDialog = true;
      } else {
        this.confirmDialog = false;
        this.reasonDialog = true;
      }
    },

		printBill({ type, columnString, columnArr,additionalOptionColumnArr }) {
			if (columnString && columnArr.length) {
				let generate_month = [];
				this.downloadColumns.map((item) => {
					if (columnArr.includes(item.value)) {
						generate_month.push(item.billing);
					}
				});
				let url =
          '?billing=' +
          generate_month.join(',') +
          '&billMonth=' +
          columnString +
          '&gradeId=' +
          this.grade;
          if(additionalOptionColumnArr.length>0){
            url+=`&${additionalOptionColumnArr.join('&')}`
          }
        if (type !== 'excel') {
          url += '&onlyDue=true';
        }
        this.$rest.get('/api/download/bill/bulk').then(({ data }) => {
          window.open(data.data.download_url + url);
        });
      } else {
        this.$events.fire('notification', {
          message: 'Please select one/more month(s)',
          status: 'error',
        });
      }
    },

    singlePrint(enCode, enrollId, bill_date, inv_sequence, onlyDue = false) {
      const generate_month = [];
      const columnString = [];

			this.selectedMonths.map((item) => {
				generate_month.push(item.billing);
				columnString.push(item.value);
			});

      const url = `?billing=${generate_month.join(
        ','
      )}&billMonth=${columnString.join(
        ','
      )}&enrollCode=${enCode}&enroll=${enrollId}&billDate=${bill_date}&inv_sequence=${inv_sequence}&onlyDue=${onlyDue}&printed_by=${
        this.$auth.user().full_name
      }`;

      this.$rest.get('/api/download/bill/single').then(({ data }) => {
        window.open(data.data.download_url + url);
      });
    },
    rollBack() {
      let formData = new FormData();
      formData.append('grade_id', this.grade);
      formData.append('bill_month', this.month);
      formData.append('billing', this.billing);
      formData.append('reason', this.reason);

      const payload = {
        rollback_invoices: [],
        rollback_enrolls: [],
        reason: this.reason,
      };

      this.rollbackSelectedStudents.map(function (item) {
        if (item.bills.length > 0) {
          payload.rollback_enrolls.push(item.enroll_id);
          payload.rollback_invoices.push(item.bills[0].invoice_no);
        }
      });

      this.$rest
        .post('/api/invoice/rollback', payload)
        .then(({ data }) => {
          this.reasonDialog = false;
          this.get();
          this.$events.fire('notification', {
            message: data.message,
            status: 'success',
          });
        })
        .catch((e) => {
          this.$events.fire('notification', {
            message: e.response.data.message,
            status: 'error',
          });
        });
    },
    getMonth() {
      if (this.grade) {
        this.monthLoading = true;
        // this.downloadColumns = [];
        this.$rest
          .get('/api/invoice/billNow?gradeId=' + this.grade)
          .then(({ data }) => {
            this.months = data.map((item) => {
              // this.downloadColumns.push({
              //   value: item.generate_month,
              //   name: item.bill_month_name,
              //   billing: item.billing,
              //   selected: false,
              // });
              if (item.generate_month === '13') {
                item.bill_month_name = 'Baisakh (13)';
              }
              if (item.generate_month === '14') {
                item.bill_month_name = 'Jestha (14)';
              }
              return {
                value: item.generate_month,
                text: item.bill_month_name,
                billing: item.billing,
                id: item.id,
              };
            });
          })
          .finally(() => {
            this.monthLoading = false;
          });
        
      }

    },
    getBilling() {
      let $this = this;
      this.filterData = this.months.filter(function (data) {
        if (data.value === $this.month) {
          return data;
        }
      });
      this.billing = this.filterData[0].billing;
    },
    findBillingMonth(month) {
      return this.months.find((res) => res.value === month);
    },
    addExtra(item) {
      this.itemData = item;
      this.fee_head_amount = '';
      this.fee_head_id = '';
      this.total = '';

      this.labelFeeHead = 'Fee Head Amount';
      this.feeOfferDiscountPer = 0;
      this.feeOfferScholarPer = 0;

      this.addDialog = true;
      this.$rest
        .get('/api/fee?gradeId=' + this.grade + '&batchId=' + this.batch.id)
        .then(({ data }) => {
          let existingDetails = item.bills[0].details;
          let existingDetailsName = getPropertyFromArrayObject(
            'fee_head',
            existingDetails
          );

          this.fees = [];
          data.data.map((res) => {
            if (
              !existingDetailsName.includes(res.fee_head.title) &&
              res.generate_bill === 0
            )
              this.fees.push({
                value: res.fee_head_id,
                text: res.fee_head.title,
                amount: res.amount,
              });
          });
        });
    },
    submit() {
      this.addDialog = false;
      this.$rest
        .post('/api/invoice/add-item', {
          invoice_no: this.itemData.bills[0].invoice_no,
          fee_head: this.fee_head,
          fee_head_amount: this.fee_head_amount,
          fee_head_id: this.fee_head_id,
          total: this.total,
          enroll_id: this.itemData.enroll_id,
          discount: this.feeOfferDiscountPer,
          scholar: this.feeOfferScholarPer,
          billing_month_id: this.findBillingMonth(this.month).id,
          grade_id: this.grade,
        })
        .then(({ data }) => {
          this.get();
          this.$events.fire('notification', {
            message: e.response.data.message,
            status: 'success',
          });
        })
        .catch((e) => {
          this.$events.fire('notification', {
            message: e.response.data.message,
            status: 'error',
          });
        });
    },
    extraDelete() {
      this.extraDeleting = true;
      this.$rest
        .delete('/api/invoice/delete-item/' + this.selectedExtraItem.details.id)
        .then(({ data }) => {
          this.get();
          this.$events.fire('notification', {
            message: data.message,
            status: 'success',
          });
          this.selectedExtraItem = null;
          this.extraDeleteAlert = false;
        })
        .catch((e) => {
          this.$events.fire('notification', {
            message: e.response.data.message,
            status: 'error',
          });
        })
        .finally(() => {
          this.extraDeleting = false;
        });
    },
    dueAmount(item, index) {
      let amount = 0;
      item.details.map((res) => {
        amount += parseFloat(res.due_amount);
      });

      let tableRows = document.getElementsByClassName('highlight');

      if (tableRows.length) {
        if (amount <= 0) {
          // tableRows.item(index).style.background = '#a9fdab';
        } else if (amount < item.amount) {
          // tableRows.item(index).style.background = '#fbcba7';
        } else {
          // tableRows.item(index).style.background = '';
        }
      }

      if (amount < 0) amount = 0;
      return amount.toFixed(2);
    },
    paidAmount(item, index) {
      let paid_amount = 0;
      item.details.map((res) => {
        paid_amount += parseFloat(res.paid_amount);
      });
      return paid_amount;
    },
    syncToAccount() {
      this.$rest
        .post('/api/billing-log-sync', { id: this.id })
        .then(({ data }) => {
          this.get();
          this.confirmSyncDialog = false;
          this.$events.fire('notification', {
            message: data.message,
            status: 'success',
          });
        })
        .catch((e) => {
          this.confirmSyncDialog = false;
          this.$events.fire('notification', {
            message: data.message,
            status: 'error',
          });
        });
    },
    selectHead(item) {
      if (this.selected.indexOf(item) > -1)
        this.selected.splice(this.selected.indexOf(item), 1);
      else this.selected.push(item);
    },
    selectStudent(item) {
      if (this.selectedTabMode === 'generate') {
        if (item.bills.length < 1)
          if (this.selectedStudents.indexOf(item) > -1)
            this.selectedStudents.splice(
              this.selectedStudents.indexOf(item),
              1
            );
          else this.selectedStudents.push(item);
      } else if (this.selectedTabMode === 'rollback') {
        if (item.bills.length > 0)
          if (this.rollbackSelectedStudents.indexOf(item) > -1)
            this.rollbackSelectedStudents.splice(
              this.rollbackSelectedStudents.indexOf(item),
              1
            );
          else this.rollbackSelectedStudents.push(item);
      }
    },

    selectCustomizedFeeHead(item) {
      if (this.selectedCustomizedFee.indexOf(item) > -1) {
        this.selectedCustomizedFee.splice(
          this.selectedCustomizedFee.indexOf(item),
          1
        );
      } else {
        this.selectedCustomizedFee.push(item);
      }
    },
    toggleAllStudent() {
      if (this.selectedStudents.length) this.selectedStudents = [];
      else
        this.selectedStudents = this.form.items.data.filter(function (item) {
          return item.bills.length < 1;
        });
    },
    toggleAllRollbackStudent() {
      if (this.rollbackSelectedStudents.length)
        this.rollbackSelectedStudents = [];
      else
        this.rollbackSelectedStudents = this.form.items.data.filter(function (
          item
        ) {
          return item.bills.length > 0 && item.flag_type === 'rollback';
        });
    },
    scrollToAdvance() {
      var top =
        document.querySelector('.advance_pending').documentOffsetTop() -
        window.innerHeight / 2;
      window.scrollTo(0, top);
    },
    fetchCustomizedFee() {
      this.selectedCustomizedFee = [];
      this.$rest.get('/api/customized-fee-heads').then(({ data }) => {
        this.selectedCustomizedFee = [...data];
        this.customizedFees = [...data];
      });
    },
    payNow(item) {
      let routeData = this.$router.resolve({
        name: 'fee-payment',
        query: {
          enroll_code: item.enroll_code,
          _s_: item.section_id,
          _e_i_: item.enroll_id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    getBillingSetting(){
      this.$rest.get('/api/billing-setting').then(({ data }) => {
        this.display_transport_alert = data.billing.exclude_transportation
      });
    }
  },
};
</script>
<style lang="scss">
.acc_selector {
  max-width: 100%;
  margin: auto;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
}

.acc_selector .select_box {
  background-color: rgba(2, 179, 100, 0.1);
  cursor: pointer;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  align-items: flex-start;
  border-radius: 5px;
  height: auto;
  width: 49%;
  padding: 9px 11px 11px;
  border: 2px solid white;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  transition: 0.4s;
  &.active {
    border: 2px solid rgb(0, 179, 101) !important;
  }
  &:hover {
    border: 2px solid rgba(0, 179, 101, 1) !important;
  }
  p {
    font-weight: normal;
    font-size: 18px;
  }
  small {
    margin-top: 6px;
    line-height: 1.3;
  }
}
.table_advance {
  width: 100%;
}
.customers {
  // font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.customers td,
.customers th {
  border: 1px solid #ddd;
  padding: 8px;
}
.customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

.customers tr:hover {
  background-color: #ddd;
}

.customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

.highlight {
  td {
    font-weight: bold !important;
  }
}
.generate_records .data-represent div {
  margin-right: 30px;
}
html {
  scroll-behavior: smooth;
}

.gen_roll_switch {
  padding: 0;
  position: absolute;
  top: 30px;
  right: 15px;

  li {
    list-style: none;
    display: inline-block;

    a {
      /*background: red;*/
      color: #555;
      padding: 10px 20px;
      text-decoration: none;
      font-weight: bold;

      i {
        font-size: 18px;
      }

      &.generate {
        background: #f1f1f1;

        &.selected {
          background: rgba(27, 117, 211, 1);
          color: white;

          i {
            color: white;
            font-size: 18px;
          }
        }

        &.disabled {
          cursor: not-allowed;
        }

        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &.rollback {
        background: #f1f1f1;
        border-left: 1px solid #ccc;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;

        &.selected {
          background: rgba(255, 82, 81, 1);
          color: white;

          i {
            color: white;
            font-size: 18px;
          }
        }

        &.disabled {
          /*background: rgba(255, 82, 81, 0.4);*/
          cursor: not-allowed;
        }
      }
    }
  }
}

.category_selector {
  position: relative;
}

.amount-chip {
  font-weight: bold;
  color: #555;
}

.accent--text {
  color: #1a75d3 !important;
  caret-color: #1a75d3 !important;
}

.expand_contract {
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  min-width: 150px;
  text-align: center;
  background: #59a2ef;
  transition: 0.2s;

  &:hover {
    background: #1a75d3;
  }

  color: white;
  font-weight: bold;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: absolute;
  top: 0;
  right: 20px;

  .v-icon {
    color: white;
  }

  i {
    color: white;
    font-size: 20px;
  }
}

tr.highlight {
  cursor: pointer;
}

.was_cancelled {
  color: #ff5252;
}
.is_posted_to_account {
  border-right: 8px solid #4caf50;
}
.advance_pending {
  background: rgba(76, 175, 80, 0.3) !important;
  &:hover {
    background: #eff3f6 !important;
  }
}
</style>
